var gnavMyAccountCardCallback = gnavMyAccountCardCallback || {};

gnavMyAccountCardCallback.myAccountLiveChat = gnavMyAccountCardCallback.myAccountLiveChat || {};

(function ($) {
  gnavMyAccountCardCallback.myAccountLiveChat = function ($html, data, user) {
  // Dyanically intialize liveperson buttons in this layer
  // See: https://connect.liveperson.com/message/3476
  // Note: this should work, but doesn't
    var numLPButtons = $html.find('.lp-button a').length;

    if (typeof lpMTag !== 'undefined' && $.isFunction(lpMTag.lpSetPage) && numLPButtons == 0) {
      var lpUnit = 'Clinique';

      lpMTagConfig.dynButton[lpMTagConfig.dynButton.length] = {
        'name': 'chat-' + lpUnit + '-product',
        'pid': 'lpbuttonproduct',
        'afterStartPage': true
      };
      lpMTagConfig.dynButton[lpMTagConfig.dynButton.length] = {
        'name': 'chat-' + lpUnit + '-ordertech',
        'pid': 'lpbuttonordertech',
        'afterStartPage': true
      };
      lpMTagConfig.dynButton[lpMTagConfig.dynButton.length] = {
        'name': 'chat-' + lpUnit + '-video',
        'pid': 'lpbuttonvideo',
        'afterStartPage': true
      };
      lpMTag.lpSetPage();
    // console.log(lpMTagConfig);
    }
  };

  $(window).on('load', function() {
    if ($('.summary-chat-now .container').length > 0) {
      $('.summary-chat-now .container').append($('#LP_DIV_1497359040757').show());
    }
  });

  $('.my_account_live_chat').on('click', function (event) {
    event.preventDefault();
    $('.LPMcontainer')[0].trigger('click');
  });
})(jQuery);
